import React from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'next/image'

export default function Program ({ week, index, styles }) {
  return (
    <div
      key={index}
      className={'card col-12 col-md-5 col-lg-4 mb-3'}
    >
      <div className='card-top'>
        <h4 className='card-title'>{week.title}</h4>
        <div className='thumbnail'>
          <Image src={week.thumbnail} alt={week.title} width={240} height={152} loading='lazy'/>
          <div className='price'>
            {week.price} <span className='unit'>{week.unit}</span>
          </div>
        </div>
      </div>
      <div className='card-middle'>
        <h3 className={'choose-program__title text-white'}>{week.middle.title}</h3>
        <span>{week.middle.subTitle}</span>
      </div>
      <div className='card-body'>
        <nav className={styles['choose-program__service-list']}>
          <ul className='nav'>
            {week.body.list &&
                            week.body.list.map((data) => {
                              return (
                                <li key={data.id} className='nav-item'>
                                  {data.text}
                                </li>
                              )
                            })}
          </ul>
        </nav>
      </div>
      <Button
        variant='danger'
        href={`/sign-up?program=${index === 0 ? `${PW4}&cmc_ref=${CMC_REF_4W}` : `${PW12}&cmc_ref=${CMC_REF_12W}`}`}
        className='week bg-red'
      >
        {week.button}
      </Button>
    </div>
  )
}
