import React from 'react'
import Row from 'react-bootstrap/Row'
import Image from 'next/image'

export default function ListServices (props) {
  return (
    <Row>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    Full access to online fitness portal
        </a>
      </div>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link active' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    Daily custom menu & video workout
        </a>
      </div>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    Access to 100s of workout videos
        </a>
      </div>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link active' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    Diary & progress tracker
        </a>
      </div>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    Ongoing support & motivation
        </a>
      </div>
      <div className='col-12 col-md-6 col-lg-4 nav-item'>
        <a className='nav-link active' href='#'>
          <Image src='/static/images/check-green.svg' alt='Check' width={21} height={21} quality={50} loading='lazy'/>
                    100% Satisfaction Guarantee
        </a>
      </div>
    </Row>
  )
}
