import React, { useState, useEffect } from 'react'

export const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='

export const LazyImage = ({
  src,
  alt,
  className,
  width,
  height
}) => {
  const [imageSrc, setImageSrc] = useState(placeHolder)
  const [imageRef, setImageRef] = useState()

  const onLoad = (event) => {
    event.target.classList.add('loaded')
  }

  const onError = (event) => {
    event.target.classList.add('has-error')
  }

  useEffect(() => {
    let observer
    let didCancel = false

    if (imageRef && imageSrc !== src) {
      if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src)
                observer.unobserve(imageRef)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '75%'
          }
        )
        observer.observe(imageRef)
      } else {
        // Old browsers fallback
        setImageSrc(src)
      }
    }
    return () => {
      didCancel = true
      // on component cleanup, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  }, [src, imageSrc, imageRef])

  return <img
    className={className}
    ref={setImageRef}
    src={imageSrc}
    alt={alt}
    onLoad={onLoad}
    onError={onError}
    width={width}
    height={height}
  />
}

export const LazyBackgroundImage = ({ loaded }) => {
  if (loaded) {
    var targetElements = [].slice.call(document.querySelectorAll('.lazy-background'))
    if ('IntersectionObserver' in window) {
      const lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            entry.target.classList.remove('lazy-background')
            lazyBackgroundObserver.unobserve(entry.target)
          }
        })
      }, {
        threshold: 0
      })

      targetElements.forEach(function (lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground)
      })
    }
  }
}
