import React, { useEffect, useState, Fragment } from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import styles from './accordion.module.scss'

function CustomToggle ({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    handleClick()
  })

  return (
    <Fragment>
      <Accordion.Button
        style={{ backgroundColor: 'transparent', display: 'block' }}
        as={Button}
        variant='link'
        key={eventKey}
        onClick={decoratedOnClick}
      >
        {children}
      </Accordion.Button>
    </Fragment>
  )
}

export default function DAccordion (props) {
  const [activeKey, setActiveKey] = useState('0')
  const [numberItem, setNumberItem] = useState(99)
  const [expand, setExpand] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [dataList, setDataList] = useState([
    {
      title: 'ARE MY DETAILS SAFE?',
      content:
        'Security is very important to us! Your payment will be safely processed using our SAFE & SECURE Secure Socket Layer (SSL). This is the highest security level for online transactions, protecting your details.'
    },
    {
      title: 'WHAT HAPPENS AFTER I JOIN?',
      content:
        'This is where the fun begins!<br/>' +
        'You get to choose your start date, personalize your goals, modify your food preference settings, set your level of exercise intensity, and begin walking the journey with the support of our global community.'
    },
    {
      title: 'WHAT IS THE CANCELLATION POLICY??',
      content:
        'We want to make sure you love this program and stand by our word!<br/>' +
        "You can cancel anytime by contacting our team at <a href='mailto:support@thedolcediet.com'>support@thedolcediet.com</a> - Terms and Conditions may apply."
    },
    {
      title: 'WHEN WILL I BE CHARGED?',
      content:
        'Initial payments for the 4-week or 12-week plans will be processed upon registration.<br/>' +
        'For monthly subscription plans, payments will be requested on the same day each month.<br/>' +
        'You may cancel at anytime.'
    },
    {
      title: 'HOW DOES THE 100% SATISFACTION GUARANTEE WORK?',
      content:
        'Take 24 hours to test out the program and make sure you love it!<br/>' +
        "If you decide within the first 24 hours that you are not ready to totally transform your life, simply send us an email to cancel at <a href='mailto:support@thedolcediet.com'>support@thedolcediet.com</a>"
    }
  ])

  const handleAddMore = (e) => {
    e.preventDefault()

    if (numberItem === 4) {
      setNumberItem(dataList.length)
      setExpand(true)
    } else {
      setExpand(false)
      setNumberItem(4)
    }
  }

  useEffect(() => {
    if (props.dataList && props.dataList.length > 0) {
      setDataList(props.dataList)
    }
  }, [props])

  return (
    <Accordion id='accordion' className={styles.accordion} defaultActiveKey={'0'}>
      <h3 className={styles.accordion__title}>Frequently asked questions</h3>
      {dataList.slice(0, numberItem).map((item, index) => {
        return (
          <Card key={index}>
            <Card.Header>
              <CustomToggle eventKey={index.toString()} handleClick={() => {
                if (activeKey === index.toString()) {
                  setActiveKey(null)
                } else {
                  setActiveKey(index.toString())
                }
              }}>{item.title}
                {
                  activeKey === index.toString() ? <FontAwesomeIcon icon={faMinus} className={'fa float-end'} />
                    : <FontAwesomeIcon icon={faPlus} className={'fa float-end'} />
                }</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body dangerouslySetInnerHTML={{ __html: item.content }}></Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })}

      {/* <Button variant='outline-secondary' className={styles['accordion__add-more'] + ' no-border no-background btn'} onClick={handleAddMore}> */}
      {/*  { expand ? 'VIEW LESS' : 'VIEW MORE' } */}
      {/*  { expand ? <MaterialIcon icon="expand_less" /> : <MaterialIcon icon="expand_more" /> } */}
      {/* </Button> */}
    </Accordion>
  )
}

DAccordion.propTypes = {
  dataList: PropTypes.array
}
