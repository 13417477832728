import React, { useEffect, useState } from 'react'
// import dynamic from 'next/dynamic'
import useMobileDetect from 'helpers/mobile-detect'
import { LazyBackgroundImage } from 'components/share/withImages'

import styles from './choose-program.module.scss'

import ProgramItem from 'components/share/choose-program/program'
import ListServices from 'components/share/choose-program/list-services'
import DAccordion from 'components/share/accordion/index'
// const ProgramItem = dynamic(() => import('components/share/choose-program/program'))
// const ListServices = dynamic(() => import('components/share/choose-program/list-services'))
// const DAccordion = dynamic(() => import('components/share/accordion/index'))

export default function Index (props) {
  const currentDevice = useMobileDetect()
  const [imageUrl, setImageUrl] = useState(
    '/static/images/bg-choose-program.jpg'
  )
  const bgImage = 'url(' + imageUrl + ') no-repeat center top / cover #000000'
  // eslint-disable-next-line no-unused-vars
  const [weekProgram, setWeekProgram] = useState([
    {
      title: '4 WEEK PROGRAM',
      thumbnail: '/static/images/programs/4w-full.png',
      middle: {
        title: '3 Weeks to Shredded+',
        subTitle: 'Lose up to 21lbs in 21 days'
      },
      price: '$3.59',
      unit: 'USD/day',
      body: {
        title: 'Drop weight fast',
        list: [
          {
            id: 1,
            text: 'Jump-start your weight-loss'
          },
          {
            id: 2,
            text: 'Shed weight quickly & safely'
          },
          {
            id: 3,
            text: 'Eat like a Pro Athlete'
          },
          {
            id: 4,
            text: 'Great for vacations or big events!'
          },
          {
            id: 5,
            text: 'Easy to follow menu & workouts'
          }
        ]
      },
      button: 'Start the 4wk program'
    },
    {
      title: '12 WEEK PROGRAM',
      thumbnail: '/static/images/programs/12w-full.png',
      price: '$1.78',
      unit: 'USD/day',
      middle: {
        title: 'Living Lean Bundle',
        subTitle: 'Lose up to 40lbs in 12 weeks'
      },
      body: {
        title: 'Sculpt the body of your dreams',
        list: [
          {
            id: 1,
            text: 'Includes 3 WEEKS TO SHREDDED'
          },
          {
            id: 2,
            text: 'Establish healthy lifestyle habits'
          },
          {
            id: 3,
            text: 'Gain energy & increase strength'
          },
          {
            id: 4,
            text: 'Sculpt & tone your body'
          },
          {
            id: 5,
            text: 'Dramatically change your life'
          }
        ]
      },
      button: 'Start the 12wk program'
    }
  ])

  useEffect(() => {
    if (currentDevice.isMobile() && currentDevice.width < 768) {
      setImageUrl('/static/images/bg-m-choose-program-mb.jpg')
    } else {
      setImageUrl('/static/images/bg-choose-program.jpg')
    }
    LazyBackgroundImage({ src: imageUrl, alt: 'Placeholder', loaded: currentDevice.width > 0 })
  }, [currentDevice.width])

  return (
    <section id='choose-program' className={styles['choose-program']}>
      <div className={styles['choose-program__wrapper'] + ' wrapper lazy-background'} style={{ background: bgImage }}>
        <div className={styles['choose-program__caption'] + ' container text-center'}>
          <h3 className={styles['choose-program__title'] + ' text-uppercase'}>choose your program</h3>
          <div className={styles['choose-program__list-service']}>
            <ListServices />
          </div>

          <div className={styles['choose-program__card-list'] + ' row'}>
            {weekProgram.map((week, index) => {
              return <ProgramItem key={index} week={week} index={index} styles={styles} />
            })}
          </div>

          <DAccordion />
        </div>
      </div>
    </section>
  )
}
